@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap);
@font-face {
  font-family: "Teshrin AR LT";
  src: url("https://db.onlinewebfonts.com/t/510d36c34e39deee4abbd9264a159c79.eot"); /* IE9*/
  src: url("https://db.onlinewebfonts.com/t/510d36c34e39deee4abbd9264a159c79.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("https://db.onlinewebfonts.com/t/510d36c34e39deee4abbd9264a159c79.woff2")
      format("woff2"),
    /* chrome firefox */
      url("https://db.onlinewebfonts.com/t/510d36c34e39deee4abbd9264a159c79.woff") format("woff"),
    /* chrome firefox */ url("https://db.onlinewebfonts.com/t/510d36c34e39deee4abbd9264a159c79.ttf")
      format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
      url("https://db.onlinewebfonts.com/t/510d36c34e39deee4abbd9264a159c79.svg#Teshrin AR LT")
      format("svg"); /* iOS 4.1- */
}

